import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";

export default function Editor({ value, onChange, height, placeholder }) {
  return (
    <AceEditor
      mode="python"
      theme="github"
      height={height || "200px"}
      width="100%"
      fontSize={16}
      enableBasicAutocompletion={true}
      enableLiveAutocompletion={true}
      value={value || null}
      onChange={onChange}
      showPrintMargin={false}
      showFoldWidgets={false}
      placeholder={placeholder}
    />
  );
}
