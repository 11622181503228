import { Flag, Medal, Question, Warning } from "phosphor-react";
import { useLoading, Audio } from "@agney/react-loading";

export default function Results({ configuration, response, loading }) {
  const { indicatorEl } = useLoading({
    loading,
    indicator: <Audio width={48} />,
  });

  let content;
  if (loading) {
    content = (
      <div className="is-flex is-justify-content-center is-align-items-center mt-5">
        {indicatorEl}
      </div>
    );
  } else {
    const hasError = response.benchmarks.find((bmark) => bmark.error);
    const allError = response.benchmarks.reduce(
      (acc, bmark) => acc && bmark.error,
      true
    );

    const outputs = response.benchmarks.map((result, i) => {
      const benchmark = configuration.benchmarks[i];
      return (
        <div>
          <div className="mt-4 mb-2">{benchmark.title || `Code ${i + 1}`}</div>
          {result.output ? (
            <pre className={result.error ? "has-text-danger" : ""}>
              {result.output}
            </pre>
          ) : (
            <div className="pre has-background-light py-4 px-5">
              <i>No output</i>
            </div>
          )}
        </div>
      );
    });

    if (allError) {
      content = (
        <div className="content">
          <div className="notification is-danger is-light is-flex is-align-items-center">
            <Warning size={24} className="mr-2" />
            There was an error with your benchmark. Check the output below for
            more information.
          </div>
          <hr />
          <h4 className="title is-6">Output</h4>
          {outputs}
        </div>
      );
    } else {
      const [rowsRuntime, minRuntime, bestName] = computeRows(
        configuration,
        response,
        (x) => x.runtime,
        formatDuration,
        (x) => (
          <td className="is-hidden-mobile">
            {formatOpsSec(1 / x.runtime)} op/s
          </td>
        ),
        0
      );

      const rowsMemory = computeRows(
        configuration,
        response,
        (x) => x.memory,
        formatMemory,
        (x) => (
          <td className="is-hidden-mobile">
            {formatOpsSec(Math.log10(1e8 / (x.runtime * (x.memory + 1))))}
          </td>
        ),
        1
      )[0];

      content = (
        <div className="content">
          {hasError ? (
            <div>
              <div className="notification is-danger is-light is-flex is-align-items-center">
                <Warning size={24} className="mr-2" />
                There was an error with one of your snippets. Check the output
                below for more information.
              </div>
              <hr />
            </div>
          ) : (
            ""
          )}

          <h4 className="title is-6">Fastest</h4>

          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <Medal size={64} />
              </div>
              <div className="level-item">
                <div className="title is-3">{bestName}</div>
              </div>
            </div>

            <div className="level-right">
              <div className="has-text-right">
                <h3 className="title is-1">{formatDuration(minRuntime)}</h3>
                <div className="subtitle">
                  {formatOpsSec(1 / minRuntime)} op/s
                </div>
              </div>
            </div>
          </div>

          <hr />

          <h4 className="title is-6">Speed</h4>
          <table className="table is-fullwidth is-bordered">
            <thead className="has-background-light">
              <tr>
                <th>Benchmark</th>
                <th>Runtime</th>
                <th title="Operations per second">
                  <div className="is-flex is-align-items-center">
                    <span>Operations</span>
                    <Question size={20} className="ml-2 has-text-grey" />
                  </div>
                </th>
                <th
                  width="0%"
                  title="Runtime VS best"
                  className="is-hidden-mobile"
                >
                  <div className="is-flex is-align-items-center">
                    <span>VS</span>
                    <Question size={20} className="ml-2 has-text-grey" />
                  </div>
                </th>
                <th width="40%">Visual</th>
              </tr>
            </thead>
            <tbody>{rowsRuntime}</tbody>
          </table>

          <hr />

          <h4 className="title is-6">Memory</h4>
          <table className="table is-fullwidth is-bordered">
            <thead className="has-background-light">
              <tr>
                <th>Benchmark</th>
                <th>Peak Memory</th>
                <th title="A combined score of memory and runtime. Higher is better (code uses less memory and is faster).">
                  <div className="is-flex is-align-items-center">
                    <span>Score</span>
                    <Question size={20} className="ml-2 has-text-grey" />
                  </div>
                </th>
                <th
                  width="0%"
                  title="Memory VS best"
                  className="is-hidden-mobile"
                >
                  <div className="is-flex is-align-items-center">
                    <span>VS</span>
                    <Question size={20} className="ml-2 has-text-grey" />
                  </div>
                </th>
                <th width="40%">Visual</th>
              </tr>
            </thead>
            <tbody>{rowsMemory}</tbody>
          </table>

          <hr />
          <h4 className="title is-6">Output</h4>
          {outputs}
        </div>
      );
    }
  }

  return (
    <div className="card mb-5">
      <header className="card-header">
        <p className="card-header-title">
          <span className="mr-2 is-flex is-align-content-center">
            <Flag size={20} />
          </span>
          <span>Results</span>
        </p>
      </header>

      <div className="results-card card-content is-relative">{content}</div>
    </div>
  );
}

function round(value) {
  if (value < 2) return Math.round(value * 100) / 100;
  if (value < 5) return Math.round(value * 10) / 10;
  return Math.round(value);
}

function formatDuration(duration) {
  let suffix = "s";

  if (duration < 1e-6) {
    duration *= 1e9;
    suffix = "ns";
  } else if (duration < 0.001) {
    duration *= 1e6;
    suffix = "us";
  } else if (duration < 1) {
    duration *= 1000;
    suffix = "ms";
  }

  return `${duration.toPrecision(3)} ${suffix}`;
}

function formatOpsSec(num) {
  let suffix = "";

  if (num > 1e6) {
    num /= 1e6;
    suffix = "M";
  } else if (num > 1000) {
    num /= 1000;
    suffix = "K";
  }

  return `${num.toPrecision(3)}${suffix}`;
}

function formatMemory(memory) {
  let suffix = "KB";

  if (memory > 1e6) {
    memory = (memory / 1e6).toPrecision(3);
    suffix = "GB";
  } else if (memory > 1e3) {
    memory = (memory / 1e3).toPrecision(3);
    suffix = "MB";
  }

  if (memory === 0) {
    memory = "< 1";
  }

  return `${memory} ${suffix}`;
}

function computeRows(configuration, response, property, format, inverse, eps) {
  const maxProp = response.benchmarks.reduce(
    (acc, val) => (val.error ? acc : Math.max(acc, property(val))),
    0
  );
  const minProp = response.benchmarks.reduce(
    (acc, val) => (val.error ? acc : Math.min(acc, property(val))),
    1e9
  );
  // Avoid sorting in-place
  const rows = [...response.benchmarks]
    .sort((a, b) => (property(a) > property(b) ? 1 : -1))
    .filter((result) => !result.error)
    .map((result) => {
      const i = response.benchmarks.indexOf(result);
      const benchmark = configuration.benchmarks[i];

      let cls;
      if (property(result) === minProp) {
        cls = "is-success";
      } else if (property(property) === maxProp) {
        cls = "is-danger";
      } else {
        cls = "is-link";
      }

      return (
        <tr>
          <td>{benchmark.title || `Code ${i + 1}`}</td>
          <td>{format(property(result))}</td>
          {inverse ? inverse(result) : ""}
          <td>{round(Math.max(1, property(result) / (minProp + eps)))}×</td>
          <td>
            <progress
              className={`progress is-small mt-2 ${cls}`}
              value={property(result)}
              max={maxProp}
            >
              {property(result)}
            </progress>
          </td>
        </tr>
      );
    });
  const bestIdx = response.benchmarks.findIndex(
    (val) => property(val) === minProp
  );
  const bestName =
    configuration.benchmarks[bestIdx].title || `Code ${bestIdx + 1}`;

  return [rows, minProp, bestName];
}
