import { Timer, Copy, Trash } from "phosphor-react";
import Editor from "./Editor";

export default function Benchmark({
  benchmark,
  setBenchmark,
  loading,
  onClone,
  onDelete,
  i,
  lastOne,
}) {
  return (
    <div>
      <div className="card mb-5">
        <header className="card-header">
          <p className="card-header-title">
            <span className="mr-2 is-flex is-align-content-center">
              <Timer size={18} />
            </span>
            <span>Code {i + 1}</span>
            <input
              className="edit-on-hover ml-3 is-flex-grow-1"
              value={benchmark.title}
              onChange={(event) =>
                setBenchmark({ ...benchmark, title: event.target.value })
              }
              placeholder="Untitled"
            />
          </p>

          <div className="card-header-icon" aria-label="open setup">
            <span className="icon is-flex is-align-content-center">
              <button
                className="button is-small ml-4"
                onClick={onClone}
                disabled={loading}
                title="Clone benchmark"
              >
                <span>Clone</span>
                <span className="icon is-flex is-align-content-center">
                  <Copy size={18} />
                </span>
              </button>

              {lastOne ? (
                ""
              ) : (
                <button
                  className="button is-small is-danger is-outlined ml-4"
                  onClick={onDelete}
                  disabled={loading}
                  title="Delete benchmark"
                >
                  <span>Delete</span>
                  <span className="icon is-flex is-align-content-center">
                    <Trash size={18} />
                  </span>
                </button>
              )}
            </span>
          </div>
        </header>

        <div>
          <div className="card-footer">
            <Editor
              value={benchmark.code}
              onChange={(code) => setBenchmark({ ...benchmark, code })}
              height="300px"
              placeholder="Put the code to measure here"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
