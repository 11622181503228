import { TwitterLogo, Book } from "phosphor-react";
import { Link } from "react-router-dom";
import logo from "./logo.png";

export default function NavBar() {
  return (
    <header className="head">
      <nav
        id="navbar"
        className="navbar is-dark"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand is-flex-grow-1">
          <Link to="/" className="navbar-item">
            <span className="icon mr-3 is-flex is-align-content-center">
              <img src={logo} alt="perfpy" />
            </span>
            <span>perfpy</span>
          </Link>
        </div>

        <div className="navbar-end is-flex is-align-items-stretch">
          <Link
            to="/docs"
            className="navbar-item px-4"
            href="https://twitter.com/perfpy"
            title="Follow me on twitter!"
          >
            <Book size={20} className="mx-2" />
            <span>Docs</span>
          </Link>

          <a
            className="navbar-item px-4"
            href="https://twitter.com/perfpy"
            title="Follow me on twitter!"
          >
            <TwitterLogo size={20} className="mx-2" />
            <span>@perfpy</span>
          </a>
        </div>
      </nav>
    </header>
  );
}
