export default function Sidebar() {
  return (
    <aside className="menu p-5 has-background-white-bis is-hidden-mobile ">
      <p className="menu-label">Popular Benchmarks</p>
      <ul className="menu-list">
        <li>
          <a href="/14">Thresholding, numpy vs OpenCV</a>
        </li>
        <li>
          <a href="/12">Replace in string</a>
        </li>
        <li>
          <a href="/16">Grow pandas DataFrame</a>
        </li>
        <li>
          <a href="/18">Unique values in array</a>
        </li>
      </ul>
    </aside>
  );
}
