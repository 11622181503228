import { Clipboard } from "phosphor-react";

export default function ShareModal({ close }) {
  return (
    <div className="modal is-active">
      <div className="modal-background" onClick={() => close()}></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Share</p>
        </header>
        <section className="modal-card-body">
          <div className="field has-addons">
            <p className="control is-expanded">
              <input
                className="input"
                type="text"
                value={window.location.href}
                autoFocus="autofocus"
                onFocus={(event) => event.target.select()}
              />
            </p>
            <p className="control">
              <button
                className="button"
                onClick={() =>
                  navigator.clipboard.writeText(window.location.href)
                }
              >
                <Clipboard />
                <span className="ml-2">Copy</span>
              </button>
            </p>
          </div>
          <p>
            Your benchmark has been permanently saved and may be accessed by
            anybody with this link.
          </p>
        </section>
        <footer className="modal-card-foot is-flex is-justify-content-end">
          <button className="button is-primary" onClick={() => close()}>
            Close
          </button>
        </footer>
      </div>
    </div>
  );
}
