import { useState, useEffect } from "react";
import { X } from "phosphor-react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import NavBar from "./NavBar";
import BenchmarkEditor from "./BenchmarkEditor";
import ShareModal from "./ShareModal";
import { loadBenchmark, runBenchmark, saveBenchmark } from "./requests";
import Results from "./Results";

export default function App() {
  const [configuration, setConfiguration] = useState({
    pythonVersion: "3.8",
    benchmarks: [{ title: "", code: "" }],
  });
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [configLoaded, setConfigLoaded] = useState(false);

  // Reset title if coming from docs
  useEffect(() => {
    document.title = `Benchmark Python Snippets Online - perfpy`;
  }, []);

  useEffect(() => {
    (async () => {
      if (window.location.pathname.length > 1) {
        const id = parseInt(window.location.pathname.replace("/", ""));
        const config = await loadBenchmark(id);

        setConfiguration({
          ...config,
          benchmarks: config.benchmarks.map(({ request }) => request),
        });
        setResponse({
          pythonVersion: config.pythonVersion,
          benchmarks: config.benchmarks.map(({ response }) => response),
        });

        if (config.title.length !== 0) {
          document.title = `Python Benchmark: ${config.title} - perfpy`;
          setConfigLoaded(true);
        } else {
          document.title = `Benchmark Python Snippets Online - perfpy`;
        }
      }
    })();
  }, []);

  async function run() {
    setResponse(null);
    setLoading(true);

    try {
      let result = await runBenchmark(configuration, setShowError);
      setResponse(result);

      setTimeout(() => {
        document
          .getElementById("result")
          .scrollIntoView({ behavior: "smooth" });
      }, 100);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  async function saveAndShare(copy) {
    if (window.saveAndShareTimeout !== null) {
      clearTimeout(window.saveAndShareTimeout);
      window.saveAndShareTimeout = null;
    }

    window.saveAndShareTimeout = setTimeout(async () => {
      const saveResult = await saveBenchmark({
        ...configuration,
        ...(response && response.pythonVersion
          ? { pythonVersion: response.pythonVersion }
          : {}),
        benchmarks: configuration.benchmarks.map((benchmark, i) => ({
          request: benchmark,
          response:
            response && response.benchmarks ? response.benchmarks[i] : {},
        })),
      });

      if (window.location.pathname !== `/${saveResult.id}`) {
        window.history.pushState({}, "", `/${saveResult.id}`);
      }

      setShowShareModal(true);

      window.saveAndShareTimeout = null;
    }, 500);
  }

  return (
    <div className="is-flex is-flex-direction-column">
      <NavBar />

      <Header
        onRun={run}
        onShare={() => saveAndShare()}
        loading={loading}
        title={configuration.title}
        onTitleChange={(title) => {
          setConfiguration({ ...configuration, title });
        }}
      />

      <div className="is-overflow-hidden is-flex is-flex-grow-1 is-justify-content-stretch">
        <Sidebar />

        <main className="is-flex-grow-1 p-5 scroll">
          <div className="container is-max-desktop">
            <div className="content mb-5">
              {configLoaded && configuration.title ? (
                <div>
                  <h1 className="title is-5">
                    Python Benchmark: {configuration.title}
                  </h1>

                  <p>
                    Looking for a fast way to "{configuration.title}" in python?
                    Check below to see see which method is the fastest and uses
                    less memory.
                  </p>
                </div>
              ) : (
                <div>
                  <h1 className="title is-5">
                    Benchmark Python Snippets Online
                  </h1>

                  <p>
                    Perfpy allows you to benchmark python snippets online and
                    share them with other people. You can measure the
                    performance and memory usage of code on different python
                    versions in this sandbox environment.
                  </p>

                  <p>
                    The tool can compare and test the speed of 3rd-party
                    libraries or library versions, so you can find out which is
                    the fastest or consumes less memory between numpy, opencv,
                    scipy and pandas for a certain function.
                  </p>
                </div>
              )}
            </div>

            <BenchmarkEditor
              configuration={configuration}
              setResponse={setResponse}
              setConfiguration={setConfiguration}
              loading={loading}
              run={run}
            />

            <div id="result">
              {loading || response ? (
                <Results
                  loading={loading}
                  configuration={configuration}
                  response={response}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </main>
      </div>

      {showError ? (
        <div className="notification floating-notification is-danger is-flex is-align-items-center">
          <span>An error occurred, please try again later.</span>
          <div
            className="delete ml-5"
            aria-label="close"
            onClick={() => setShowError(false)}
          >
            <X color="white" />
          </div>
        </div>
      ) : (
        ""
      )}

      {showShareModal ? (
        <ShareModal close={() => setShowShareModal(false)} />
      ) : (
        ""
      )}
    </div>
  );
}
