import { CaretDown, Package, Plus, PuzzlePiece } from "phosphor-react";
import { useRef, useState } from "react";
import Benchmark from "./Benchmark";
import Card from "./Card";
import useOnClickOutside from "use-onclickoutside";

const VERSIONS = ["3.6", "3.7", "3.8", "3.9"];

export default function BenchmarkEditor({
  configuration,
  loading,
  setResponse,
  setConfiguration,
}) {
  const [pythonVersionOpen, setPythonVersionOpen] = useState(false);

  const ref = useRef();
  useOnClickOutside(ref, () => setPythonVersionOpen(false));

  function addExperiment(template = { title: "", code: "" }) {
    setConfiguration({
      ...configuration,
      benchmarks: [
        ...configuration.benchmarks,
        JSON.parse(JSON.stringify(template)),
      ],
    });
  }

  function removeExperiment(index) {
    setConfiguration({
      ...configuration,
      benchmarks: [
        ...configuration.benchmarks.slice(0, index),
        ...configuration.benchmarks.slice(index + 1),
      ],
    });
    setResponse(null);
  }

  return (
    <div>
      <div className="level">
        <div className="level-right">
          <div className="level-item">
            <div
              className={`dropdown ${pythonVersionOpen ? "is-active" : ""}`}
              ref={ref}
            >
              <div className="dropdown-trigger">
                <button
                  className="button"
                  aria-haspopup="true"
                  aria-controls="dropdown-menu"
                  onClick={() => setPythonVersionOpen(!pythonVersionOpen)}
                >
                  <span>Python {configuration.pythonVersion}</span>
                  <span className="icon">
                    <CaretDown />
                  </span>
                </button>
              </div>

              <div className="dropdown-menu" id="dropdown-menu" role="menu">
                <div
                  className="dropdown-content"
                  onClick={() => setPythonVersionOpen(false)}
                >
                  {VERSIONS.map((version) => (
                    <a
                      key={version}
                      href={"#" + version}
                      className="dropdown-item"
                      onClick={() =>
                        setConfiguration({
                          ...configuration,
                          pythonVersion: version,
                        })
                      }
                    >
                      Python {version}
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Card
        icon={<Package size={18} />}
        title="Packages"
        help={
          <span>
            List packages as it's done in a{" "}
            <code className="mx-1">requirements.txt</code> file.{" "}
          </span>
        }
        value={configuration.requirements}
        onChange={(requirements) =>
          setConfiguration({ ...configuration, requirements })
        }
        accordion={true}
        initialOpen={configuration.requirements}
      />

      <Card
        icon={<PuzzlePiece size={18} />}
        title="Setup"
        help="Runs before the benchmark, and it is not measured. Good place to
              import libraries and prepare data."
        value={configuration.setup}
        onChange={(setup) => setConfiguration({ ...configuration, setup })}
        accordion={true}
        initialOpen={configuration.setup}
      />

      {configuration.benchmarks.map((benchmark, i) => {
        return (
          <div key={i}>
            <Benchmark
              benchmark={benchmark}
              i={i}
              setBenchmark={(benchmark) =>
                setConfiguration({
                  ...configuration,
                  benchmarks: [
                    ...configuration.benchmarks.slice(0, i),
                    benchmark,
                    ...configuration.benchmarks.slice(i + 1),
                  ],
                })
              }
              loading={loading}
              onClone={() => addExperiment(benchmark)}
              lastOne={configuration.benchmarks.length === 1}
              onDelete={() => removeExperiment(i)}
            />
          </div>
        );
      })}

      <div className="level">
        <div className="level-left"></div>
        <div className="level-right">
          <div className="level-item">
            <button
              className="button"
              disabled={loading}
              onClick={() => addExperiment()}
            >
              <span>Compare</span>
              <span className="icon">
                <Plus size={24} />
              </span>
            </button>
          </div>
        </div>
      </div>

      <Card
        icon={<PuzzlePiece size={18} />}
        title="Teardown"
        help="Runs after the benchmark, and it is not measured. Good place to
              print out results."
        value={configuration.teardown}
        onChange={(teardown) =>
          setConfiguration({ ...configuration, teardown })
        }
        accordion={true}
        initialOpen={configuration.teardown}
      />
    </div>
  );
}
