import { CaretDown, CaretUp } from "phosphor-react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Editor from "./Editor";

export default function Card({
  icon,
  title,
  help,
  value,
  onChange,
  initialOpen,
}) {
  const [open, setOpen] = useState(!!initialOpen);

  useEffect(() => initialOpen && setOpen(!!initialOpen), [initialOpen]);

  return (
    <div className="card mb-5">
      <header className="card-header" onClick={() => setOpen(!open)}>
        <p className="card-header-title">
          <span className="mr-2 is-flex is-align-content-center">{icon}</span>
          <span>{title}</span>
        </p>

        <div className="card-header-icon" aria-label="open setup">
          <span className="icon is-flex is-align-content-center">
            {open ? <CaretUp size={18} /> : <CaretDown size={18} />}
          </span>
        </div>
      </header>

      {open ? (
        <div>
          {help ? (
            <div className="card-content">
              {help} <Link to="/docs">Learn more</Link>
            </div>
          ) : (
            ""
          )}
          <div className="card-footer">
            <Editor value={value} onChange={onChange} />
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
