import { Notebook, Play, Plus, ShareNetwork } from "phosphor-react";
import { useLoading, Audio } from "@agney/react-loading";

export default function Header({
  onRun,
  onShare,
  loading,
  title,
  onTitleChange,
}) {
  const { indicatorEl } = useLoading({
    loading,
    indicator: <Audio width={24} />,
  });

  function newBenchmark() {
    window.location.pathname = "/";
  }

  return (
    <header className="head has-background-light">
      <div className="level">
        <div className="level-left is-flex">
          <div className="level-item p-4">
            <span className="mr-3 is-flex is-align-content-center">
              <Notebook size={24} />
            </span>
            <h2 className="title is-4">
              <input
                className="edit-on-hover"
                type="text"
                value={title || ""}
                onChange={(event) => onTitleChange(event.target.value)}
                placeholder="Untitled Benchmark"
              />
            </h2>
          </div>
        </div>

        <div className="level-right is-mobile">
          <div className="navbar-item is-hidden-mobile">
            <div className="level-item my-4">
              <button className="button" onClick={newBenchmark}>
                <span>New Benchmark</span>
                <span className="icon is-flex is-align-content-center">
                  <Plus size={24} />
                </span>
              </button>
            </div>

            <div className="level-item my-4">
              <button
                className="button"
                onClick={onShare}
                title="Save & Share Benchmark"
              >
                <span>Share</span>
                <span className="icon is-flex is-align-content-center">
                  <ShareNetwork size={24} />
                </span>
              </button>
            </div>

            <div className="level-item mr-4 my-4">
              <button
                className="button is-primary"
                onClick={onRun}
                disabled={loading}
              >
                <span>Run</span>
                <span className="icon is-flex is-align-content-center">
                  {loading ? indicatorEl : <Play size={24} weight="fill" />}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
