const baseUrl =
  process.env.NODE_ENV === "development" ? "http://localhost:17554" : "";

export async function runBenchmark(data, setShowTimeoutError) {
  const resp = await fetch(`${baseUrl}/run`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  if (resp.status !== 200) {
    setShowTimeoutError(true);
    setTimeout(() => {
      setShowTimeoutError(false);
    }, 10000);
    throw Error("Invalid result");
  }

  return await resp.json();
}

export async function saveBenchmark(data) {
  const resp = await fetch(`${baseUrl}/save`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  if (resp.status !== 200) {
    throw Error("Invalid result");
  }

  return await resp.json();
}

export async function loadBenchmark(id) {
  const resp = await fetch(`${baseUrl}/benchmark/${id}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  if (resp.status !== 200) {
    throw Error("Invalid result");
  }

  return await resp.json();
}
