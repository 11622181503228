import { useEffect } from "react";
import NavBar from "./NavBar";

export default function Docs() {
  // Change title
  useEffect(() => {
    document.title = `Documentation - perfpy`;
  }, []);

  return (
    <div className="is-flex is-flex-direction-column">
      <NavBar />

      <main className="section scroll">
        <div className="container is-max-desktop">
          <div className="content mb-5">
            <h1 className="title is-4">
              Perfpy - Benchmark Python Snippets Online
            </h1>

            <p>
              Perfpy allows you to benchmark python snippets online and share
              them with other people. It can measure both the speed and the
              memory consumption of python code. Perfpy features:
            </p>

            <ul>
              <li>Benchmark performance of python code.</li>
              <li>Measure peak memory consumption of code snippets.</li>
              <li>Test different python versions.</li>
              <li>Compare different libraries and library versions.</li>
              <li>Test the output of different snippets.</li>
              <li>
                Accurately measure speed using setup and teardown to isolate
                non-relevant code.
              </li>
              <li>
                Share results on Q&A sites and on merge request with friends and
                colleagues.
              </li>
            </ul>

            <h3 className="title is-5">Installing and Testing Dependencies</h3>

            <p>
              In the <strong>Packages</strong> section you can install 3rd party
              packages to test in your snippets. Packages can be listed in the
              standard format used in <code>requirements.txt</code> files, for
              instance:
            </p>

            <pre>
              # Any version
              <br />
              numpy
              <br />
              <br />
              # A range
              <br />
              numpy>1.18
              <br />
              <br />
              # A specific version
              <br />
              numpy==1.19.0
              <br />
              <br />
              # A github repository
              <br />
              git+https://github.com/numpy/numpy.git
              <br />
            </pre>

            <p>
              The execution time for the benchmark is capped and installing
              dependencies can take long. In order to avoid errors, please:
            </p>

            <ul>
              <li>
                Do not specify a package version if not needed. This way a
                cached version can be used.
              </li>
              <li>
                Use the <code>-headless</code> variant of the libraries if
                available. For instance <code>opencv-python-headless</code>
                does not install lots of graphical components that are useless
                in the sandbox.
              </li>
            </ul>

            <p>
              Popular packages that are pre-installed for your convenience are:
              numpy, scipy, scikit-learn, scikit-image, pandas, urllib3,
              requests, pillow, lxml, opencv-python-headless.
            </p>

            <h3 className="title is-5">Setup & Teardown</h3>

            <p>
              The setup and teardown boxes allow you to define common code
              snippets that are executed before and after each test respectively
              (they will be run separately for each test, not only once).
            </p>

            <p>
              The runtime and memory usage of setup and teardown code is not
              measured and does not figure in the result value. This makes these
              snippets ideal for importing libraries, preparing test data and
              printing results without affecting the test.
            </p>

            <h3 className="title is-5">How Measurements are Performed</h3>

            <p>
              The code is run multiple times and the minimum time is taken as a
              result. This ensures that the code is hot and the measurement is
              not skewed. The number of times the code is run is determined by a
              heuristic, if the runtime of a single execution is very large, it
              can be that the code is run only once to avoid timeouts.
            </p>

            <p>
              The memory usage measurement can detect at a minimum a kilobyte
              and has a granularity of a kilobyte. For this reason it is not
              useful to micro-benchmark snippets, but it is very useful when
              testing libraries such as numpy or opencv, where the memory usage
              can easily be megabytes.
            </p>
          </div>
        </div>
      </main>
    </div>
  );
}
